import { render, staticRenderFns } from "./SocialCard.vue?vue&type=template&id=82303692&scoped=true"
import script from "./SocialCard.vue?vue&type=script&lang=js"
export * from "./SocialCard.vue?vue&type=script&lang=js"
import style0 from "./SocialCard.vue?vue&type=style&index=0&id=82303692&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82303692",
  null
  
)

export default component.exports